<template>
  <div class="user-profile-container">
    <router-view></router-view>
    <section class="profile-statistic">
      <div class="profile-statistic-general-info">
        <div class="left-info">
          <div class="greeting-user-name">
            hello, {{ currentUser.first_name }}!
          </div>
          <div class="membership-title">
            YOUR MEMBERSHIP
          </div>
          <div class="membership-date">
            Member since 2020
          </div>
          <div class="membership-statistics">
            <div class="membership-statistics-points">
              <div class="membership-statistics-points-count">
                10
              </div>
              <div class="membership-statistics-points-title">
                Points Earned
              </div>
            </div>
            <div class="membership-statistics-level">
              <div class="membership-statistics-level-img">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="51" height="29" viewBox="0 0 51 29">
                  <image id="Vector_Smart_Object" data-name="Vector Smart Object" width="51" height="29" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAAdCAYAAAAHB1RsAAADR0lEQVRYhdWYyU7bUBSGf2cgQAKUIUDKPEYIseANvOxTdNHH6qKLbqq+RHW3ZYEQiEGIOSEIEiAMCQlDUv2nMSKCQmIfVPgkiB37Xp8/557B10IFY0wEwEcAjXgflAHkASRt2y7SYhFjjPkM4BsA/zsR8pAsgE+2bf/2GWNCAL6+UyHkA4AfPLCMMTEAqf9vk2cCgdfyiN/vR0dHB1paWuT4+voa2WwWZ2dnryXGCrzGrLFYDKOjowgEHk9/cXGB9fV1XF5eqj9XXczY2Bj6+/vl+Pj4GCcnJ7i5uUFTUxN6enrEU7Ozs1haWhJPaaIqpru7W4Tc3d1heXkZp6enVdcTiQRGRkYwMDCA6elpzM3NiVAtfFoTWZYlS4usrq4+EkLK5TK2trZwdHQkS3BwcFBNCDTFtLa2IhQK4fz8XJbXc2xvb8vVaDSq9XhBTUw4HJZPxshLFAoF5PN5Ef9UknCLmhjHKMZLLdze3laNe1NiWEcIs9ZLML6c+5xxGqiJcdIs44BF8jk6OzsRDAYlvkql0tsTwzhg4NPIiYmJf97X0NCA8fFxOd7f39d6vKBaZzY2NtDW1ibFkbGwubmJq6ur++v0CIUy8CmcKVoTVTH0zuLiImZmZsRw/lGM0wHQa4Q1aGVlRVUINJcZiUQiGBoaujcalYTAGvTwO7Y0w8PDqpkMWp5hdnLaFFTSbiaTkaRAzzDIaTjFspNub2+Xe3t7e7G2tlZTbarJDmMMu8KE2wl8Pp/0WTSSRu/t7SGZTD5bb+gttj5dXV1yzi764ODArQkOQc/LbGpqSoQwXubn57G7u/ti4aS32IhSBJmcnFRpbTyJ6evrk1+XhW9hYQG5XK6u8fQGlxmJx+NobPS2l+JaDAOacUKYmYrFoqt5Dg8Ppd6w0PJdyAuuxdArNIDGeH0VZhfN9E0vNzc3u57HtRgWRsKA9wpjLJVKVc3rVkyh3kHMRlzfjBG28hqk02mZhWnbJSWKYZKvqxw77y7cnNCCPww95MxdJz9t2y75+I87ggB+1TqezSIq7YsmTCKsW3V0BrT9O4AvPLnfaybGGJ5XffcU0WjUCofDVi6XK6fTae75quDMu7OzU+t7Qdm27b/PB/AHIhhKV28iyuYAAAAASUVORK5CYII="/>
                </svg>
              </div>
              <div class="membership-statistics-level-level">
                Silver
              </div>
              <div class="membership-statistics-level-title">
                Membership
              </div>
            </div>
          </div>
        </div>
        <div class="right-info">
          <div class="membership-info">
            <div class="membership-info-title">
              MEMBERSHIP NO.
            </div>
            <div class="membership-info-no">
              6501825778965
            </div>
          </div>
        </div>
      </div>
      <div class="profile-statistic-menu">
        <ul class="profile-statistic-menu-list">
          <li class="profile-statistic-menu-list-item" @click="$router.push('/user/profile/details')">
            <div class="profile-statistic-menu-list-item-content">
              <div class="profile-menu-item-title">
                PERSONAL INFO
              </div>
              <div class="profile-menu-item-subtitle">
                Your details & Account
              </div>
            </div>
            <div class="profile-statistic-menu-list-item-arrow">
              <vs-icon icon="navigate_next" size="50px"></vs-icon>
<!--              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 22 43">-->
<!--                <image id="Vector_Smart_Object" data-name="Vector Smart Object" width="40" height="40" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAArCAYAAAB8UHhIAAAChklEQVRIia3XS4hPURzA8e/cIwsLoqgpG7KVBWVDt2uhGKE8iiJKs/AoRcnIb/HbWMzCblgpjyYUFh4Zj/7+GONRk0fRMDaaooSUvMbp6uQ39W/85z/3P/f8dqd7/p//uefx+52LE53hRCcRORLgGNDvRFtj0gHeDDwB7jvRedFgr+KBHcAFoNeJLogBt9Q2nOg+4DCw2qvcLQO72kZerfQlafYBOJOk2cu8WhmIAhv+NKCGvw/tKLDhA0ma9QInkzQbDm/SLNzS6KEt5I3wB8ABr5JHgQ0PW7AHuAO02y4qDxveavhbYJNX+RkFNnwacBX4A6zxKl8b9U+KwgYtB74BFSc6Mwps+HdgLfAC6HOic6LAhoep2AZctvwyv16/wnNcL5xoB7AfWOVVHtR2qXtAikZerdxL0uyTndJnebXyJgrMP7w/SbPXQHeSZu/yauV5FNjwV0maPbQU8COvVh6VmuPR4UQXAteB403vinFiEBgC5kYbsROdYgnrM7A+CuxEJ9txD2u2MuSS0mXfiQbsPDAVyEYSVCnYiYY3PhXmFFhqR55SsKFdwCJgyehsV2bER4AVhn4c/XBCsBM9BGwBUq8yVK9P07AT3QPsBZZ5lcGx+jW13ZzoduBoSPhe5XGjvs2Upg3ACaCtyC2paDFtA84B67xKT5HfjJsrnGgGnAW2FkXHhZ3oYuASsNOrXCyKNoStll0DOrzK6WbQMWG7/dwCOr1KV7Mo9RbPic4OpT3UMa9ycCLof7ATnQWEatvjVXZNFKV2KpzodOB2+FwAdpdBGRmxEw259KaVlY1Fb5QNYSspV4Bfdtn7XRYNEaai20pKOFVR0BAhu7UDX7zKcCwU4C+hROuaPt0Y7AAAAABJRU5ErkJggg=="/>-->
<!--              </svg>-->
            </div>
          </li>
          <li class="profile-statistic-menu-list-item" @click="$router.push('/user/profile/appointments-schedules')">
            <div class="profile-statistic-menu-list-item-content">
              <div class="profile-menu-item-title">
                APPOINTMENTS &<br> MEDICAL HISTORY
              </div>
              <div class="profile-menu-item-subtitle">
                Appointments & treatments history
              </div>
            </div>
            <div class="profile-statistic-menu-list-item-arrow">
              <vs-icon icon="navigate_next" size="50px"></vs-icon>
<!--              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 22 43">-->
<!--                <image id="Vector_Smart_Object" data-name="Vector Smart Object" width="40" height="40" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAArCAYAAAB8UHhIAAAChklEQVRIia3XS4hPURzA8e/cIwsLoqgpG7KVBWVDt2uhGKE8iiJKs/AoRcnIb/HbWMzCblgpjyYUFh4Zj/7+GONRk0fRMDaaooSUvMbp6uQ39W/85z/3P/f8dqd7/p//uefx+52LE53hRCcRORLgGNDvRFtj0gHeDDwB7jvRedFgr+KBHcAFoNeJLogBt9Q2nOg+4DCw2qvcLQO72kZerfQlafYBOJOk2cu8WhmIAhv+NKCGvw/tKLDhA0ma9QInkzQbDm/SLNzS6KEt5I3wB8ABr5JHgQ0PW7AHuAO02y4qDxveavhbYJNX+RkFNnwacBX4A6zxKl8b9U+KwgYtB74BFSc6Mwps+HdgLfAC6HOic6LAhoep2AZctvwyv16/wnNcL5xoB7AfWOVVHtR2qXtAikZerdxL0uyTndJnebXyJgrMP7w/SbPXQHeSZu/yauV5FNjwV0maPbQU8COvVh6VmuPR4UQXAteB403vinFiEBgC5kYbsROdYgnrM7A+CuxEJ9txD2u2MuSS0mXfiQbsPDAVyEYSVCnYiYY3PhXmFFhqR55SsKFdwCJgyehsV2bER4AVhn4c/XBCsBM9BGwBUq8yVK9P07AT3QPsBZZ5lcGx+jW13ZzoduBoSPhe5XGjvs2Upg3ACaCtyC2paDFtA84B67xKT5HfjJsrnGgGnAW2FkXHhZ3oYuASsNOrXCyKNoStll0DOrzK6WbQMWG7/dwCOr1KV7Mo9RbPic4OpT3UMa9ycCLof7ATnQWEatvjVXZNFKV2KpzodOB2+FwAdpdBGRmxEw259KaVlY1Fb5QNYSspV4Bfdtn7XRYNEaai20pKOFVR0BAhu7UDX7zKcCwU4C+hROuaPt0Y7AAAAABJRU5ErkJggg=="/>-->
<!--              </svg>-->
            </div>
          </li>
          <li class="profile-statistic-menu-list-item">
            <div class="profile-statistic-menu-list-item-content">
              <div class="profile-menu-item-title">
                REWARDS & POINTS
              </div>
              <div class="profile-menu-item-subtitle">
                Rewards & points history
              </div>
            </div>
            <div class="profile-statistic-menu-list-item-arrow">
              <vs-icon icon="navigate_next" size="50px"></vs-icon>
<!--              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 22 43">-->
<!--                <image id="Vector_Smart_Object" data-name="Vector Smart Object" width="40" height="40" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAArCAYAAAB8UHhIAAAChklEQVRIia3XS4hPURzA8e/cIwsLoqgpG7KVBWVDt2uhGKE8iiJKs/AoRcnIb/HbWMzCblgpjyYUFh4Zj/7+GONRk0fRMDaaooSUvMbp6uQ39W/85z/3P/f8dqd7/p//uefx+52LE53hRCcRORLgGNDvRFtj0gHeDDwB7jvRedFgr+KBHcAFoNeJLogBt9Q2nOg+4DCw2qvcLQO72kZerfQlafYBOJOk2cu8WhmIAhv+NKCGvw/tKLDhA0ma9QInkzQbDm/SLNzS6KEt5I3wB8ABr5JHgQ0PW7AHuAO02y4qDxveavhbYJNX+RkFNnwacBX4A6zxKl8b9U+KwgYtB74BFSc6Mwps+HdgLfAC6HOic6LAhoep2AZctvwyv16/wnNcL5xoB7AfWOVVHtR2qXtAikZerdxL0uyTndJnebXyJgrMP7w/SbPXQHeSZu/yauV5FNjwV0maPbQU8COvVh6VmuPR4UQXAteB403vinFiEBgC5kYbsROdYgnrM7A+CuxEJ9txD2u2MuSS0mXfiQbsPDAVyEYSVCnYiYY3PhXmFFhqR55SsKFdwCJgyehsV2bER4AVhn4c/XBCsBM9BGwBUq8yVK9P07AT3QPsBZZ5lcGx+jW13ZzoduBoSPhe5XGjvs2Upg3ACaCtyC2paDFtA84B67xKT5HfjJsrnGgGnAW2FkXHhZ3oYuASsNOrXCyKNoStll0DOrzK6WbQMWG7/dwCOr1KV7Mo9RbPic4OpT3UMa9ycCLof7ATnQWEatvjVXZNFKV2KpzodOB2+FwAdpdBGRmxEw259KaVlY1Fb5QNYSspV4Bfdtn7XRYNEaai20pKOFVR0BAhu7UDX7zKcCwU4C+hROuaPt0Y7AAAAABJRU5ErkJggg=="/>-->
<!--              </svg>-->

            </div>
          </li>
          <li class="profile-statistic-menu-list-item">
            <div class="profile-statistic-menu-list-item-content">
              <div class="profile-menu-item-title" @click="logout">
                log out
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <section class="membership-status">
      <div class="membership-status-title">
        YOUR MEMBERSHIP STATUS
      </div>
      <div class="membership-status-text">
        This is your personal space. Get the low down on your membership status and all the points or rewards you've earned.
      </div>
      <div class="how_earn_points">
        HOW DO I EARN POINTS?
      </div>
      <div class="learn_rewards">
        LEARN ABOUT REWARDS
      </div>
    </section>
    <section class="suggested_services">
      <suggested-classes-carousel></suggested-classes-carousel>
      <interest-classes-carousel></interest-classes-carousel>
    </section>
  </div>
</template>

<script>

import SuggestedClassesCarousel from '@/components/service-carousel/SuggestedClassesCarousel.vue';
import InterestClassesCarousel from '@/components/service-carousel/InterestClassesCarousel.vue';
import axios from "@/axios";

export default {
  data() {
    return {
    }
  },
  components: {
    SuggestedClassesCarousel,
    InterestClassesCarousel
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logout');
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.userProfile;
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";

.user-profile-container {
  max-width: 1400px;
  margin: 0 auto 100px;

  .profile-statistic {
    width: 100%;
    display: flex;
    padding: 0 35px;

    .profile-statistic-general-info {
      display: flex;
      justify-content: space-between;
      width: 65%;
      background: url('../../assets/images/pages/user-profile/user-profile-statistic-bg.png') center no-repeat;
      background-size: cover;
      padding: 40px 70px 55px 65px;

      .left-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        .greeting-user-name {
          color: $white;
          font-size: 40px;
          font-weight: 700;
          text-transform: uppercase;
          margin-bottom: 40px;
        }
        .membership-title {
          color: $lightYellow;
          font-size: 26px;
          font-weight: 700;
        }
        .membership-date {
          color: $white;
          font-style: italic;
          font-size: 14px;
          margin-bottom: 20px;
        }
        .membership-statistics {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          background-color: $white;
          padding: 20px 40px;
          .membership-statistics-points {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-right: 1px solid var(--lGreen);
            padding-right: 25px;
            .membership-statistics-points-count {
              color: var(--lGreen);
              font-size: 42px;
              font-weight: 700;
            }
            .membership-statistics-points-title {
              color: $lightGreen;
              font-weight: 300;
            }
          }
          .membership-statistics-level {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-left: 35px;
            .membership-statistics-level-level {
              font-size: 22px;
              font-weight: 700;
              color: var(--lGreen);
            }
            .membership-statistics-level-title {
              color: $lightGreen;
              font-weight: 300;
            }
          }
        }
      }
      .right-info {
        .membership-info {
          padding-top: 60px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .membership-info-title {
            padding-right: 38px;
            text-transform: uppercase;
            color: $lightYellow;
            font-size: 26px;
            font-weight: 700;
          }
          .membership-info-no {
            color: $white;
          }
        }
      }
    }
    .profile-statistic-menu {
      width: 35%;
      padding-left: 50px;
      .profile-statistic-menu-list {
        .profile-statistic-menu-list-item {
          padding: 20px 0;
          border-top: 1px solid var(--lGreen);
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .profile-menu-item-title {
            color: var(--lGreen);
            font-size: 24px;
            font-weight: 700;
            line-height: 1.2;
          }
          .profile-menu-item-subtitle {
            color: $lightGreen;
            font-size: 18px;
            font-weight: 300;
          }
          .profile-statistic-menu-list-item-arrow {
            padding-right: 20px;
            transition-duration: 500ms;
            transition-property: padding-right;
          }
          &:hover {
            .profile-menu-item-title {
              color: $lightGreen;
              transition-duration: 500ms;
              transition-property: color;
            }
            .profile-statistic-menu-list-item-arrow {
              padding-right: 0;
            }
          }
          &:last-child {
            padding-top: 60px;
            padding-bottom: 0;
            .profile-menu-item-title {
              text-transform: uppercase;
              font-size: 18px;
              font-weight: 700;
              text-decoration: underline;
              &:hover {
                cursor: pointer;
              }
            }
            &:hover {
              cursor: auto;
            }
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  .membership-status {
    margin-top: 60px;
    padding: 0 65px;
    .membership-status-title {
      font-size: 24px;
      color: var(--lGreen);
      font-weight: 700;
    }
    .membership-status-text {
      margin-top: 5px;
      font-size: 22px;
      color: $lightGreen;
    }
    .how_earn_points, .learn_rewards {
      color: var(--lGreen);
      text-transform: uppercase;
      font-size: 22px;
      font-weight: 700;
      text-decoration: underline;
      text-decoration-thickness: 1px;
      &:hover {
        color: $lightGreen;
        cursor: pointer;
      }
      &.how_earn_points {
        margin-top: 40px;
        margin-bottom: 20px;
      }
    }
  }
  .suggested_services {
    margin-top: 100px;
  }
  .interest-classes {
    margin-top: 80px;
  }
}

@media (max-width: 1200px) {
  .profile-statistic {
    flex-direction: column;
    .profile-statistic-general-info {
      flex-direction: column;
      padding-right: 0 !important;
      padding-left: 0 !important;
      width: 100% !important;
    }
    .profile-statistic-menu {
      width: 100% !important;
      padding: 30px !important;
      .profile-statistic-menu-list {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
        .profile-statistic-menu-list-item {
          border-top: none !important;
          &:last-child {
            padding: 0 !important;
          }
          padding: 0 10px !important;
          .profile-statistic-menu-list-item-arrow {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .user-profile-container {
    .profile-statistic {
      padding: 0 10px;
    }
    .membership-status {
      padding: 0 15px;
    }
  }
  .profile-statistic {
    .profile-statistic-menu {
      .profile-statistic-menu-list {
        .profile-statistic-menu-list-item {
          .profile-menu-item-title {
            font-size: 22px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .profile-statistic {
    .profile-statistic-menu {
      padding-left: 0 !important;
      padding-right: 0 !important;
      .profile-statistic-menu-list {
        .profile-statistic-menu-list-item {
          .profile-menu-item-title {
            font-size: 18px !important;
          }
          .profile-menu-item-subtitle {
            font-size: 14px !important;
          }
        }
      }
    }
  }
  .membership-status {
    .membership-status-title {
      font-size: 20px !important;
    }
    .how_earn_points {
      font-size: 20px !important;
    }
    .learn_rewards {
      font-size: 20px !important;
    }
  }
}

@media (max-width: 480px) {
  .profile-statistic {
    .profile-statistic-menu {
      padding-left: 0 !important;
      padding-right: 0 !important;
      .profile-statistic-menu-list {
        display: flex;
        flex-direction: column;
        .profile-statistic-menu-list-item {
          width: 100%;
          padding: 8px 0 !important;
          border-top: 1px solid var(--lGreen) !important;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .profile-statistic-menu-list-item-arrow {
            display: block !important;
          }
          &:last-child {
            padding-top: 8px !important;
          }
        }
      }
    }
  }
}

</style>
